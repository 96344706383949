@mixin base_dark {
  color: $fg-color-dark;
  background-color: $bg-color-dark;

  a {
    color: $link-color-dark;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $alt-fg-color-dark;

    &:hover .heading-link {
      visibility: visible;
    }

    .heading-link {
      color: $link-color-dark;
      font-weight: inherit;
      text-decoration: none;
      font-size: 80%;
      visibility: hidden;
    }

    .title-link {
      color: inherit;
      font-weight: inherit;
      text-decoration: none;
    }
  }

  code {
    background-color: $alt-bg-color-dark;
    color: $fg-color-dark;
  }

  // fix color schemes which do not explicitly set fg-color
  .highlight {
    pre {
      color: $fg-color;
    }
  }

  pre {
    code {
      background-color: inherit;
      color: inherit;
    }
  }

  blockquote {
    border-left: 2px solid $alt-bg-color-dark;
  }

  th,
  td {
    padding: 1.6rem;
  }

  table {
    border-collapse: collapse;
  }

  table td,
  table th {
    border: 2px solid $alt-fg-color-dark;
  }

  table tr:first-child th {
    border-top: 0;
  }

  table tr:last-child td {
    border-bottom: 0;
  }

  table tr td:first-child,
  table tr th:first-child {
    border-left: 0;
  }

  table tr td:last-child,
  table tr th:last-child {
    border-right: 0;
  }
}

body.colorscheme-dark {
  @include base_dark();
}

body.colorscheme-auto {
  @media (prefers-color-scheme: dark) {
    @include base_dark();
  }
}
