@mixin footer_dark {
  .footer {
    a {
      color: $link-color-dark;
    }
  }
}

body.colorscheme-dark {
  @include footer_dark();
}

body.colorscheme-auto {
  @media (prefers-color-scheme: dark) {
    @include footer_dark();
  }
}
