@mixin content_dark {
  .content {
    .post {
      .tags {
        .tag {
          background-color: $alt-bg-color-dark;

          a {
            color: $fg-color-dark;
          }
          a:active {
            color: $fg-color-dark;
          }
        }
      }
    }
    .list {
      ul {
        li {
          .title {
            color: $fg-color-dark;

            &:hover,
            &:focus {
              color: $link-color-dark;
            }
          }
        }
      }
    }

    .centered {
      .about {
        ul {
          li {
            a {
              color: $fg-color-dark;

              &:hover,
              &:focus {
                color: $link-color-dark;
              }
            }
          }
        }
      }
    }
  }
}

body.colorscheme-dark {
  @include content_dark();
}

body.colorscheme-auto {
  @media (prefers-color-scheme: dark) {
    @include content_dark();
  }
}
